import React from "react";
import Layout from "components/Layout";

const NotFoundPage = () => (
    <Layout>
        <h1>
            PUSLAPIS NERASTAS
        </h1>
        <p>
            Deja tokio puslapio nėra.
        </p>
    </Layout>
)

export default NotFoundPage;